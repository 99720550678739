.login-container{
  height: 100vh;
}

 .login-head{
   border-radius: 5px;
 }
 
 .login-head *{
  display: inline-block;
  vertical-align: middle ;
  color: #3a62ab;
  font-weight: 600;
  margin: 0px;
  margin-left: 10px;
  
 }

 .login-card{
   border: none !important;
 }
 
 .update-container{
   text-align: right;
 }
.flex-grow{
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-drawer{
  width: 240px;
}
.drawer-tool{
  margin-left: 20px;
  margin-right: 20px;
}


.main-wrap{
  display: flex;
  flex-flow: column;
  overflow: hidden;
  height: 100vh;
}
.page-wrap{
  flex-grow: 1;
  overflow: auto;
}

.page-container{
  flex-grow: 1;
  overflow: auto;
  align-items: flex-start;
 }

.fdatagrid-head{
  background-color: gainsboro;
}
.expanel-details{
  padding: 0px !important;
}

th, td {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tbcell.Default, .tbcell.Near{
text-align: left;
}
.tbcell.Far{
text-align: right;
}
.tbcell.Center{
text-align: center;
}

.page-header{

padding: 10px;
} 

.drawer-list {
  flex-grow: 1;
  overflow: auto;
}

.copyright{
  font-size: 11px;
  padding: 5px 5px;
  display: flex;
  align-items: flex-end;
}

.copyright a{
  text-decoration: none;
  color: #315ba2;
  font-weight: bold;
  padding-left: 2px;
}

.copyright a:hover{
  text-decoration: underline;
}

.cm-bubble{
  font-size: 13px;
  border: 1px solid #AED581;
  padding: 7px;
  background-color: #A5D6A7;
  margin: 2px 5px !important;
  border-radius: 10px;
  display: flex;
}
.cm-bubble .icon{
  color: #E91E63;
}

.facts-lookup,.facts-lookup * {
  cursor: pointer; 
}

/*Listview style - Custom */
/* .dx-list-item,
.lst-alt-red {
  margin: 0px 5px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  border-radius: 4px;
  cursor: pointer;
} */
/* .dx-list-item:nth-child(even), 
.dx-list-next-button .dx-button,
.lst-alt-red:nth-child(even){
  background-color: #c62828;
  color:white
} */
.dx-list .dx-empty-message, .dx-list-item-content{
  padding: 0px !important; 
}
/*Listview style - Custom */


/*MUI override */
.MuiDialog-container {
  background-color: #00000099;
}
/*MUI Override */ 

 
.update-banner .content{
  padding: 15px;
}

.update-banner .content .label{
  font-size: 1.2rem;
  padding-bottom: 10px;
}

.update-banner .content .button{
  padding: 10px 50px;
  background-color: #2196F3;
  border: 1px solid white;
  color: white;
  font-size: 1rem;
  border-radius: 8px;
}


/*Disable autofill color on webkit browsers */
input:-webkit-autofill, 
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  background-clip: text;
}
/*Disable autofill color on webkit browsers */

.list-active{
  background-color: #009688;
}
 .dz-ctr{
  box-shadow: 0px 0px 0px 1px silver !important;
 }
.dz-ctr:hover {
  padding: 10px !important;
  box-shadow: 0px 0px 0px 1px #F44336 !important;
  outline: 1px dashed #fff !important;
  overflow: visible !important;
}

.dz-ctr:hover > .dz-ctr {
  padding: 5px !important;
  overflow: visible !important;
}

span.dz-ctr:hover:after, .dz-ctr:hover > span.dz-ctr:after{
  content: ' '; 
  display: inline-block; 
  width:20px;
  height: 20px;
}

.dz-ctr.dz-noclick .MuiIconButton-label, 
.dz-ctr.dz-noclick .MuiFormControl-root,
.dz-ctr.dz-noclick .MuiInputBase-root
{
  pointer-events: none !important;
}
.dz-ctr.dz-noclick .MuiTouchRipple-root{
  display:none !important;
}
.dz-ctr.dz-noclick .MuiButton-label{
  padding: 0 !important; 
}
/* .dz-ctr:hover .dz-ctr:after {
  content: ' '; 
   
} */
.dz-ctr.dz-active {
  box-shadow: 0px 0px 0px 2px #E91E63 !important;
  outline: 2px dashed #fff !important;
  padding: 10px !important ;
}

.facts-multiFABDiv{
  position: absolute;
  bottom: 25px;
  right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.facts-multiFABDiv .MuiFab-root{
  position: relative !important;
  bottom: 0;
  right: 0 ;
  margin-bottom: 10px;
}
 
.facts-multiFABDiv .MuiFab-root:not(.MuiFab-extended){
  width: 50px !important;
  height: 50px !important;
}
.facts-multiFABDiv .facts-FABSubs
{
  display: flex;
  flex-direction: column;
  align-items:flex-end;
}